












































































































import { Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { CommunityTrainingExposureModel, CommunityMatchExposureModel, ExposureCollectionModel } from '@/core/webapi';
import { InputSelectCpt, InputTextareaCpt, ValidationSummaryCpt } from '@/core/components';
import { QcStatusTagCpt } from '../../components';
import { QcStatus } from '@/core/constants';

type ReviewAndSaveModel = ExposureCollectionModel | CommunityTrainingExposureModel | CommunityMatchExposureModel;

@Component({
  components: {
    InputSelectCpt,
    InputTextareaCpt,
    ValidationSummaryCpt,
    QcStatusTagCpt,
  },
})
export default class ReviewAndSaveDialogCpt extends AppVue {
  @Prop() onSave: (model: ReviewAndSaveModel) => Promise<void>;

  model = {} as ReviewAndSaveModel;
  showReporterDialog = false;
  showAnalystDialog = false;

  async initializeSaveItem(model: ReviewAndSaveModel) {
    this.model = model;
    (this.model as any).modelState = null;

    if (this.isReporter) {
      this.showReporterDialog = true;
    } else {
      this.showAnalystDialog = true;
    }
  }

  async onReporterDialogSaveItem() {
    const saveModel = _.cloneDeep(this.model);
    saveModel.qcStatusId = saveModel.isReadyForQc ? QcStatus.AwaitingAnalyst : QcStatus.AwaitingReporter;
    this.showReporterDialog = false;
    (this.model as any) = {};
    await this.onSave(saveModel);
  }

  onReporterDialogClose() {
    this.showReporterDialog = false;
    (this.model as any) = {};
  }

  async onAnalystDialogSaveItem() {
    const saveModel = _.cloneDeep(this.model);

    if (saveModel.isQcComplete) {
      saveModel.qcStatusId = QcStatus.Complete;
    } else {
      saveModel.qcStatusId = saveModel.requireReporterInfo ? QcStatus.AwaitingReporter : QcStatus.AwaitingAnalyst;
    }

    this.showAnalystDialog = false;
    (this.model as any) = {};
    await this.onSave(saveModel);
  }

  onAnalystDialogClose() {
    this.showAnalystDialog = false;
    (this.model as any) = {};
  }
}
