




























































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import {
  ExposureCalendarModelCalendarModel,
  ExposureCalendarModelCalendarDayModel,
  ExposureLookupModel,
} from '@/core/webapi';
import { ApiService, ReportLookupService } from '@/core/services';
import { InputSelectCpt } from '@/core/components';
import { StudyTypes } from '@/core/constants';
import { ExposuresGridHeaderCpt } from './components';

@Component({
  components: {
    ExposuresGridHeaderCpt,
    InputSelectCpt,
  },
})
export default class ExposureCalendarView extends AppVue {
  model = {} as ExposureCalendarModelCalendarModel;
  lookups = {} as ExposureLookupModel;
  filter = {
    organisationId: null as any,
    studyId: null as any,
  };
  studyTypeId = StudyTypes.Elite;

  monthsLookup = {
    1: this.$t('lbl.january'),
    2: this.$t('lbl.february'),
    3: this.$t('lbl.march'),
    4: this.$t('lbl.april'),
    5: this.$t('lbl.may'),
    6: this.$t('lbl.june'),
    7: this.$t('lbl.july'),
    8: this.$t('lbl.august'),
    9: this.$t('lbl.september'),
    10: this.$t('lbl.october'),
    11: this.$t('lbl.november'),
    12: this.$t('lbl.december'),
  };
  years = _.range(new Date().getFullYear(), 2019, -1);
  months = _.range(1, 13);
  selectedYear = this.years[0];
  selectedMonth = new Date().getMonth() + 1;

  get organisations() {
    return ReportLookupService.filterOrgs(this.lookups, StudyTypes.Elite);
  }

  get studies() {
    return ReportLookupService.filterOrgStudies(this.lookups, this.filter.organisationId, StudyTypes.Elite);
  }

  async created() {
    await this.initializeLookups();
    await this.initializeData();
  }

  getMonthName(month: number) {
    return (this.monthsLookup as any)[month];
  }

  isActiveDay(day: ExposureCalendarModelCalendarDayModel) {
    return day?.data?.noOfForwardsSessions || day?.data?.noOfBacksSessions;
  }

  getForwards(day: ExposureCalendarModelCalendarDayModel) {
    return day?.data?.noOfForwardsSessions ?? 0;
  }

  getBacks(day: ExposureCalendarModelCalendarDayModel) {
    return day?.data?.noOfBacksSessions ?? 0;
  }

  async onOrganisationChange() {
    ReportLookupService.resolveStudyId(this.studies, this.filter, true, StudyTypes.Elite);
    await this.initializeData();
  }

  async onStudyChange() {
    await this.initializeData();
  }

  async prevMonth() {
    this.selectedMonth--;
    if (this.selectedMonth < 1) {
      this.selectedYear--;
      this.selectedMonth = 12;
    }
    await this.initializeData();
  }

  async nextMonth() {
    this.selectedMonth++;
    if (this.selectedMonth > 12) {
      this.selectedYear++;
      this.selectedMonth = 1;
    }
    await this.initializeData();
  }

  async onYearChange(year: number) {
    this.selectedYear = year;
    await this.initializeData();
  }

  async onMonthChange(month: number) {
    this.selectedMonth = month;
    await this.initializeData();
  }

  async initializeLookups() {
    this.lookups = (
      await ApiService.exposures().getExposureFormLookupData(this.userContext.currentOrganisationId ?? null)
    ).data;

    ReportLookupService.resolveStudyOrOrgIds(
      this.lookups,
      this.filter,
      this.isAnalyst || this.isDevEnv,
      this.studyTypeId,
      this.isReporter,
    );
  }

  async initializeData() {
    if (!this.filter.organisationId || !this.filter.studyId) {
      return;
    }

    this.model = (
      await ApiService.exposures().getExposuresCalendarSummary(
        this.selectedYear,
        this.selectedMonth,
        this.currentOrganisationId,
        this.filter.studyId,
      )
    ).data;
  }
}
