





















































































































































import { Component, Prop } from 'vue-property-decorator';
import _ from 'lodash';

import { ExposureLookupModel, ExposureModel, ExposureSessionModel } from '@/core/webapi';
import { LookupsService, ReportLookupService, AutoSaveService, ApiService } from '@/core/services';
import {
  BaseFormComponent,
  InputFieldCpt,
  InputDateCpt,
  InputNumberCpt,
  InputSelectCpt,
  ValidationHintCpt,
  SpinnerCpt,
} from '@/core/components';
import { StudyTypes } from '@/core/constants';

class InternalExposureModel extends ExposureModel {
  /* eslint-disable @typescript-eslint/no-inferrable-types */
  noOfForwardsSessions: number = 1;
  noOfBacksSessions: number = 0;
  noOfSessions: number = 0;
  /* eslint-enable @typescript-eslint/no-inferrable-types */
}

@Component({
  components: {
    InputFieldCpt,
    InputDateCpt,
    InputNumberCpt,
    InputSelectCpt,
    ValidationHintCpt,
    SpinnerCpt,
  },
})
export default class ExposureFormCpt extends BaseFormComponent {
  @Prop() exposureId?: number;
  @Prop() autoSaveId?: number;

  lookups = {} as ExposureLookupModel;
  model = new InternalExposureModel({
    forDate: new Date() as any,
    noOfInjuries: 0,
    noOfForwardsSessions: 1,
    noOfBacksSessions: 0,
    noOfSessions: 0,
    sessions: [],
    modelState: {},
  } as any);
  comparisonModel: InternalExposureModel;
  loading = true;

  autoSaveTimeoutId?: number;

  private DEFAULT_TOTAL_MINUTES = 60;
  private DEFAULT_TOTAL_PLAYERS = 25;

  get organisations() {
    return ReportLookupService.filterOrgs(this.lookups, StudyTypes.Elite);
  }

  get studies() {
    return ReportLookupService.filterOrgStudies(this.lookups, this.model.organisationId, StudyTypes.Elite);
  }

  get selectedStudy() {
    return this.studies ? this.studies.find(p => p.id === this.model.studyId) : null;
  }

  async created() {
    try {
      this.lookups = (await ApiService.exposures().getExposureFormLookupData()).data;
      await this.initializeModel();
      this.initializeAutoSave();
    } finally {
      this.loading = false;
    }
  }

  destroyed() {
    this.clearAutoSaveTimeout();
  }

  onOrganisationChange() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    delete this.model.studyId;
  }

  adjustForwardsSessionAmount(noOfForwardsSessions: number) {
    this.model.noOfForwardsSessions = noOfForwardsSessions;
    this.adjustSessionAmount(this.getForwardsPositionId() as number);
  }

  adjustBacksSessionAmount(noOfBacksSessions: number) {
    this.model.noOfBacksSessions = noOfBacksSessions;
    this.adjustSessionAmount(this.getBacksPositionId() as number);
  }

  adjustSessionAmount(sessionPositionId: number) {
    const currentSessions = this.model.sessions;
    this.model.noOfSessions = this.model.noOfBacksSessions + this.model.noOfForwardsSessions;

    while (currentSessions && currentSessions.length > this.model.noOfSessions) {
      const lastPositionedIndex = _.findLastIndex(currentSessions, session => {
        return session.sessionPositionId === sessionPositionId;
      });

      _.remove(currentSessions, (session, index) => {
        return index === lastPositionedIndex;
      });
    }

    while (currentSessions && currentSessions.length < this.model.noOfSessions) {
      currentSessions.push(this.createSession(sessionPositionId));
    }

    this.$forceUpdate();
  }

  getSessionPositionText(index: number, sessionPositionId: number) {
    return ReportLookupService.getSessionPositionText(index, sessionPositionId, this.model, this.lookups);
  }

  async onSubmitForm() {
    this.clearAutoSaveTimeout();

    try {
      this.exposureId
        ? await ApiService.exposures().updateExposure(this.model).handleValidationErrors(this.model)
        : await ApiService.exposures().createExposure(this.model).handleValidationErrors(this.model);
    } catch (e) {
      // Typically we can allow the failed request (422) to stop the execution flow of onSubmitForm(),
      // but it breaks the autoSave feature timeout, so we need to handle it explicitly
      return;
    }

    this.$notify({
      title: this.$t('msg.success') as string,
      message: this.$t('msg.exposureReportSaved') as string,
      type: 'success',
    });

    if (this.model.autoSaveId) {
      await ApiService.autoSave().deleteAutoSave(this.model.autoSaveId);
      this.clearAutoSaveTimeout();
    }

    this.$router.push('/exposures');
  }

  async discard() {
    if (!confirm(this.$t('confirm.discardReportChanges') as string)) {
      return;
    }

    this.clearAutoSaveTimeout();
    if (!this.model.autoSaveId) {
      return;
    }

    if (this.model.autoSaveId) {
      await ApiService.autoSave().deleteAutoSave(this.model.autoSaveId);
    }

    this.$router.push('/exposures');
  }

  private async initializeModel() {
    if (this.exposureId) {
      const model =
        (await AutoSaveService.findExposureAutoSave(this.exposureId)) ||
        (await ApiService.exposures().getExposure(this.exposureId).getDataAndDefaultValidationProps());

      _.extend(this.model, {
        ...model,
        noOfSessions: model?.sessions?.length ?? 0,
        noOfForwardsSessions: this.getNoOfForwardsSessions(model),
        noOfBacksSessions: this.getNoOfBacksSessions(model),
      });
    } else if (this.autoSaveId) {
      const model = await AutoSaveService.getExposureAutoSave(this.autoSaveId);
      _.extend(this.model, model);
    } else {
      this.adjustForwardsSessionAmount(1);
      ReportLookupService.resolveOrgStudyFilterIds(this.lookups, this.model, false, StudyTypes.Elite);
    }

    this.updateComparisonModel();
  }

  private updateComparisonModel() {
    this.comparisonModel = JSON.parse(JSON.stringify(this.model)); // Make a copy
  }

  private getNoOfForwardsSessions(model: ExposureModel | null) {
    if (!model) {
      return 0;
    }

    return _.size(
      _.filter(model.sessions, {
        sessionPositionId: this.getForwardsPositionId(),
      }),
    );
  }

  private getNoOfBacksSessions(model: ExposureModel | null) {
    if (!model) {
      return 0;
    }

    return _.size(
      _.filter(model.sessions, {
        sessionPositionId: this.getBacksPositionId(),
      }),
    );
  }

  private getForwardsPositionId() {
    return LookupsService.findLookupId(this.lookups.sessionPositions!, 'Forwards') as number;
  }

  private getBacksPositionId() {
    return LookupsService.findLookupId(this.lookups.sessionPositions!, 'Backs') as number;
  }

  private createSession(sessionPositionId: number): ExposureSessionModel {
    return {
      sessionTypeId: undefined,
      sessionMinutes: this.DEFAULT_TOTAL_MINUTES,
      noOfPlayers: this.DEFAULT_TOTAL_PLAYERS,
      sessionPositionId,
    } as any;
  }

  private initializeAutoSave() {
    if (!this.isReporter) {
      // Auto-saving is only for reporters really
      return;
    }

    this.clearAutoSaveTimeout();

    this.autoSaveTimeoutId = setTimeout(async () => {
      if (!this.isAuthenticated) {
        return;
      }

      if (this.autoSaveTimeoutId) {
        const autoSave = await AutoSaveService.autoSaveExposure(this.model, this.comparisonModel);
        if (!!autoSave) {
          this.model.autoSaveId = autoSave.id;
          this.updateComparisonModel();
        }
        this.initializeAutoSave();
      }
    }, 5000);
  }

  private clearAutoSaveTimeout() {
    if (this.autoSaveTimeoutId) {
      clearTimeout(this.autoSaveTimeoutId);
      this.autoSaveTimeoutId = undefined;
    }
  }
}
